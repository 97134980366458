.nav__panel--left--sns {
  max-width: 400px;
}

.nav.has-sub .nav--sub {
  background: #3357ad !important;
}

.c-signup__btn {
  padding: 4px 12px;
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.3em;
  font-weight: 600;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  border: solid 1px #fff;
  border-radius: 150px;
  -webkit-border-radius: 150px;
  -moz-border-radius: 150px;
  text-transform: uppercase;
  margin: 8px 16px 8px 0;
}
.c-signup__btn:hover {
  cursor: pointer;
  opacity: 0.9;
}
.c-signup__btn__icon {
  margin-right: 8px;
}
.header-signup-btn-label {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}